import React from 'react'
import serviceShape4 from '../../assets/images/services/service-shape4.png'
import contact from '../../assets/images/icones/contact.gif'
import adresse from '../../assets/images/icones/adresse.gif'
import horaires from '../../assets/images/icones/horaires.gif'

const ContactInfo = () => {
    return (
        <div className="contact-info-area ptb-70">
            <div className="container">
                <div className="row" id="infos-contact">
                    <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={horaires} alt="horaires" />
                            </div>
                            <h3>Horaires</h3>
                            <p><span className='italic'>Lundi :</span> 09:00-12:30 / 14:00-18:00</p>
                            <p><span className='italic'>Mardi :</span> 09:00-12:30 / 14:00-18:00</p>
                            <p><span className='italic'>Mercredi :</span> 09:00-12:30 / 14:00-17:30</p>
                            <p><span className='italic'>Jeudi :</span> 09:00-12:30 / 14:00-18:00</p>
                            <p><span className='italic'>Vendredi :</span> 09:00-12:30 / 14:00-17:30</p>
                            <p><span className='italic'>Samedi et Dimanche :</span> Fermé</p>
                            <p><span className='italic bold center'>SAV 7/7 par mail</span></p>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={contact} alt="contact" />
                            </div>
                            <h3>Contact</h3>
                            <p><span className='italic'>Tél.:</span> <a href="tel:+33223443649">(+33) 2 23 44 36 49</a></p>
                            <p><span className='italic'>Port.:</span> <a href="tel:+33640732312">(+33) 6 40 73 23 12</a></p>
                            <p><span className='italic'>E-mail:</span> <a href="mailto:contact@seekreet.com">contact@seekreet.com</a></p>
                            <p><a href="https://fr.linkedin.com/company/seekreet" target="_blank" rel="noreferrer"><i class='bx bxl-linkedin'></i> Seekreet</a></p>
                            <p><a href="https://www.linkedin.com/in/wispeek-solution" target="_blank" rel="noreferrer"><i class='bx bxl-linkedin'></i> Wispeek Solution</a></p>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={adresse} alt="adresse" />
                            </div>
                            <h3>Adresse</h3>
                            <p>127, Rue du temple de Blosne,</p>
                            <p>35136 Saint-Jacques-de-la-Lande,</p>
                            <p>BRETAGNE</p>
                            <p>FRANCE</p>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ContactInfo