import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import PageBanner from '../components/Common/PageBanner'
import ContactInfo from '../components/Contact/ContactInfo'
import ContactForm from '../components/Contact/ContactForm'
import RendezVous from '../components/Contact/RendezVous'
import { Helmet } from 'react-helmet'

const Contact = () => {
    return (
        <Layout>
            <Helmet>
                <title>Seekreet | Nous contacter</title>
                <meta name="description" content="Seekreet, vous souhaitez obtenir plus d'informations sur nos produits ? Une démo gratuite ? Contactez-nous. Mail 24h/24 7j/7." />
            </Helmet>
            <Navbar />
            <PageBanner
                pageTitle="Contact" 
                homePageText="Accueil" 
                homePageUrl="/" 
                activePageText="Contact" 
            />
            <RendezVous />
            <ContactForm />
            <ContactInfo />
            <Footer />
        </Layout>
    );
}

export default Contact