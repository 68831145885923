import React, { useState } from 'react'
import starIcon from '../../assets/images/star-icon.png'
import contact from '../../assets/images/contact.png'
import { Link } from 'gatsby'

import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
  } from "react-google-recaptcha-v3"

const ContactForm = () => {
    const [token, setToken] = useState('')
    const verifyRecaptchaCallback = React.useCallback((token) => {
        setToken(token)
    }, []);
    return (
        <section className="contact-area ptb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="contact" /> 
                            Contact
                    </span>
                    <h2>Formulaire de contact</h2>
                    <p>Votre adresse email ne sera pas publiée. Les champs requis sont indiqués par *</p>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="contact-image">
                            <img src={contact} alt="contact" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="contact-form">
                            <GoogleReCaptchaProvider reCaptchaKey="6LeUPWMaAAAAACmWkfCCftwtYp5_wJ8b0iCT4sQA">
                                <form id="contactForm" method="post" action="https://wspk.me/contact">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <input type="text" name="name" className="form-control" required placeholder="Nom *" />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <input type="email" name="email" className="form-control" required placeholder="Email *" />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <input type="text" name="phone_number" className="form-control" placeholder="Téléphone" />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <textarea name="message" className="form-control" cols="30" rows="6" required placeholder="Votre message *" />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <input style={{marginRight:'8px',marginTop:'-2px'}} type="checkbox" id="rgpd" name="rgpd_ok" value="OUI" />
                                            <label style={{fontSize:'14px'}} for="rgpd">Je ne souhaite pas recevoir des newsletter de Seekreet</label>
                                            <p style={{fontSize:'12px',color:'grey'}}>Seekreet traite vos données à caractère personnel dans le cadre de votre demande de téléchargement de livre blanc et dans le cadre de ses actions de prospection commerciale. Pour en savoir plus sur la gestion de vos données personnelles et pour exercer vos droits, reportez-vous à <Link to="/mentions-legales">notre politique de confidentialité</Link>.</p>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <input
                                                    type="text" 
                                                    className="remarque form-control"
                                                    name="remarque"
                                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                                    placeholder="nom@domaine.com" />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <button type="submit" className="default-btn">
                                                <i className="flaticon-tick"></i> 
                                                Envoyer <span></span>
                                            </button>
                                        </div>
                                        <GoogleReCaptcha
                                            onVerify={verifyRecaptchaCallback}
                                        />
                                    </div>
                                </form>
                            </GoogleReCaptchaProvider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactForm